<template>
  <div style="width:100vw;height:100vh;">
    <!-- <iframe src="https://juejin.cn/" width="100%" height="100%" frameborder="0" referrerpolicy="origin"></iframe> -->
    <!-- <iframe id="external-content" style="width: 100%; height: 100%;" frameborder="0"></iframe> -->
    <div v-html="externalContent" ></div>
    <!-- <object data="https://www.baidu.com"></object> -->
  </div>
    <!-- <iframe src="https://www.wanjunshijie.com/demo/shujudaping/zhihuifengdian/vue3/#/" width="1920" height="1080" style="border:none;" sandbox="allow-scripts allow-same-origin"></iframe> -->
    

</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const externalContent = ref('');  // 用来存储外部内容
    const loadExternalPage = async (url) => {
      try {
        const response = await fetch(url);
        const data = await response.text();
        externalContent.value = data; // 将内容赋值给 ref 变量
        history.pushState({}, '', '/your-original-url');  // 修改浏览器的地址栏
      } catch (error) {
        console.error('Error loading external content:', error);
      } finally {
        // isLoading.value = false;  // 加载完成后隐藏 loading
      }
    };

    // 使用 onMounted 钩子在组件加载时调用
    onMounted(() => {
      loadExternalPage('https://juejin.cn/post/6991828558096105485');  // 这里传入目标外部网站
    });

    return {
      externalContent,
    };
  }
};
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #1b1b1b;
}
.charts-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 10px 0;
}
</style>
