<!-- src/views/Home.vue -->
<template>
  <el-container>
    <el-aside width="260px" class="chat-list">
      <div style="height:20px;"></div>
      <div style="padding:0 13px;">
        <div class="text-left" style="padding:0px 8px;">
          <h3 style="color:#7d7d7d;font-weight: 600;font-size: .75rem;line-height: 1rem;">最近七天</h3>
        </div>
        <div style="text-align:left;color:#0d0d0d;font-size:14px;padding: 8px;">对话一</div>
        <div style="text-align:left;color:#0d0d0d;font-size:14px;padding: 8px;border-radius:6px;background-color:#ececec;">对话二</div>
      </div>
    </el-aside>
    <el-main style="padding:0px;">
      <div style="min-height:90vh;">
        <div class="flex flex-align-center fs-12 space-between" style="background-color:#f7f7f8;color:#3d3f3f;height:24px;padding:6px 16px;">
          <router-link to="/">
            <div style="color:#3d3f3f;text-decoration: none;">首页</div>
          </router-link>
          <div style='font-size:18px;font-weight: 400;'>{{aiVersionName}}</div>
        </div>
        <div class="chat-box" ref="msgContainerRef">
          <div class="messages" >
            <div v-for="(message, index) in messages" :key="index" :class="message.role == 'user' ? 'user-info' : 'assistant-info'"
                 style="display:flex;margin:28px 0;">
              <div v-if="message.role == 'user'" style="display: flex;flex-direction: column;align-items: flex-end;">
                <div class="chat-extra" v-if="message.extra">
                  <el-icon style="color:#f57373;"><Document /></el-icon>
                  <span class="ml-5">{{ message.extra }}</span>
                </div>
                <div class="chat-content" >{{ message.content }}</div>
              </div>
              <div class="chat-content-assistant" v-if="message.role == 'assistant'">
                <el-image class="ai-robot-img"
                          :src="aiRobotAvatar"  />
                <div style="width:700px;">
                  <div>
                    <span v-if="message.content_type==='text'">{{message.content}}</span>
                    <img v-if="message.content_type==='image'" style="max-width:400px;" :src="message.content" alt="">
                  </div>
<!--                  <v-md-preview :text="message.content" style="padding:0px;"></v-md-preview>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-affix position="bottom" :offset="40">
        <div style="width:760px;margin:0 auto;">


          <div class="search-input" :class="{ focused: isFocused }">
            <div class="flex flex-center">
              <!-- <div class="flex flex-center " style="">
                <el-icon><Expand /></el-icon>
              </div> -->
              <input v-model="newMessage" @keyup.enter="sendMessage" type="text" class="no-border" style="padding:10px;width:740px;font-size:14px;" placeholder="请输入问题" @focus="handleFocus" @blur="handleBlur">
              <div class="flex flex-center ml-10">
                <el-image style="width: 32px; height: 32px"
                          src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/arrow-circle-up.png" @click="sendMessage"/>
              </div>
            </div>
          </div>
        </div>
      </el-affix>
    </el-main>
  </el-container>
</template>

<script>
import { ref ,nextTick, reactive} from 'vue';



// import ChatBox from '@/components/ChatBox.vue';
import { useRoute } from 'vue-router';
import {apiDallE3} from "@/api/chat/chat";


export default {
  name: 'ImgChat',

  setup() {
    const route = useRoute();
    const ai_type = route.query.ai_type;
    const engineType = route.query.engine_type;
    const askContent = route.query.content;


    const internetFlag = ref(false);
    const messages = ref([]);
    const newMessage = ref('');
    const msgContainerRef = ref(null);
    const aiVersionName = ref('');
    const fileLoading = ref(true);
    const fileUploadInput = ref(null);
    const fileUploadName = ref("");

    const aiRobotAvatar = ref('https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png');

    if (ai_type === 'dall-e-3') {
      aiVersionName.value = 'DALL·E 3';
    }

    const dalle3 = async () => {
      if (newMessage.value != '') {
        let new_value = {
          "role": "user",
          "content": newMessage.value,
          "extra": fileUploadName.value ? fileUploadName.value : '',
        }
        messages.value.push(new_value);
        fileUploadName.value = '';//清空文件内容
      }

      const requestMessage = newMessage.value;
      nextTick(()=>{
        scrollBottom();
      });
      newMessage.value = '';

      let tmpContent = reactive({
        "role": "assistant",
        "content": "⚫",
        "content_type": 'text',
      });
      messages.value.push(tmpContent);
      let param =  {
        "messages": requestMessage,
        "engineType": engineType
      }
      tmpContent.content_type =  'image';
      tmpContent.content =  "https://api.deepbricks.ai/api/download/image?image_id=image_gm6aEgWhf85QcShK9pGl7TIoU07voC8G.png";
      nextTick(()=>{
        scrollBottom();
      });
      // apiDallE3(param).then(res => {
      //   if (res.data.data.length> 0) {
      //     tmpContent.content =  res.data.data[0].url;
      //     tmpContent.content_type =  'image';
      //     nextTick(()=>{
      //       scrollBottom();
      //     });
      //   }
      // })
    }

    const aiOperate = (aiType, askContent = '') => {
      if (askContent != '') {
        newMessage.value = askContent;

        if (ai_type == 'dall-e-3') {
          dalle3();
        }
      }


      // chatGpt();

      // xinghuoAi();
    }

    if (askContent != '') {
      aiOperate(ai_type, askContent)
    }

    const sendMessage =  () => {
      let askConten = newMessage.value.trim();
      if (askConten !== '') {
        aiOperate(ai_type, askConten)
      }
    }

    const isFocused = ref(false);

    const handleFocus = () => {
      isFocused.value = true;
    }
    const handleBlur = () =>{
      isFocused.value = false;
    }

    const scrollBottom = ()=>{
      msgContainerRef.value.scrollTop = msgContainerRef.value.scrollHeight;
    }





    // 点击按钮时触发文件输入框
    const triggerFileUploadInput = () => {
      fileUploadInput.value.click();
    };




    return {
      ai_type,
      messages,
      newMessage,
      msgContainerRef,
      sendMessage,
      aiRobotAvatar,
      aiVersionName,

      internetFlag,

      isFocused,
      fileUploadInput,
      fileUploadName,
      fileLoading,
      handleFocus,
      handleBlur,
      triggerFileUploadInput,
    };
  }
}
</script>

<style scoped>
.chat-list {
  background-color:#f9f9f9;
}
.chat-box {
  width: 100%;
  height: 80vh;
  overflow-y:auto;
}

.messages {
  width: 800px;
  margin: 0 auto;
}

.user-info {
  justify-content: flex-end;
}

.assistant-info {
  justify-content: flex-start;
}
.chat-content {
  background-color: #f4f4f4;
  display: inline-block;
  padding: 10px 16px;
  max-width: 600px;
}
.chat-content-assistant {
  display: flex;
}
.chat-content,.chat-content-assistant {

  border-radius: 6px;
  color: #0d0d0d;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
  //white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-break: anywhere;
}

.search-input {
  border-radius:8px;
  background-color:#fff;
  padding:10px 20px;
  width:760px;
  /* margin:0px auto; */
  /* box-shadow: 5px 4px 6px #edf3ff, 5px 8px 12px #edf3ff; */
  border: 1px solid #ebeefa;
}

.search-input.focused {
  border-color: #2775ff;
}

.batch-info {
  width:32px;
  height:32px;
  background-color:#f7f7f7;
  border-radius: 50%;
}

.ai-robot-img {
  width: 30px;
  height: 30px;
  margin-top: -5px;
  margin-right:10px;
}

.search-extra-list {
  width:90px;
  margin:0 auto;
  display: flex;
  align-items:center;
  padding:10px;
  border-radius:6px;
  cursor:pointer;
}

.search-extra-list:hover {
  background-color:#EBEDF3;
}

.chat-extra {
  border: 1px solid #f4f4f4;padding: 10px 16px;border-radius:6px;margin-bottom:4px;
}

</style>
<style>
.github-markdown-body {
  padding:0px !important;
  font-size:14px !important;
}

.github-markdown-body li {
  //margin: 0 !important;
}
.github-markdown-body li>p {
  //margin: 0px !important;
  display: inline;
}
.github-markdown-body h1, .github-markdown-body h2, .github-markdown-body h3, .github-markdown-body h4, .github-markdown-body h5, .github-markdown-body h6 {
  margin: 0 !important;
}
</style>
