<template>
  <div id="ai-set">
    <div style="margin:40px;text-align:left;">
      <h1 class="set-title">精选 AI 产品集</h1>
    </div>
    <div>
      <div style="margin-left:40px; text-align:left;">AI 产品清单</div>
      <div style="width:980px;margin:0 auto;">
        <svg width="1100" height="1100" viewBox="-550,-550,1100,1100" style="width: 100%; height: auto; font: 10px sans-serif;">
          <g fill="none" stroke="#555" stroke-opacity="0.4" stroke-width="1.5">
            <path d="M0,0C-15.24,98.832,22.252,-97.493,44.504,-194.986"></path>
            <path d="M0,0C-15.24,98.832,74.564,-66.635,149.128,-133.269"></path>
            <path d="M0,0C-15.24,98.832,99.018,-13.979,198.036,-27.958"></path>
            <path d="M0,0C-15.24,98.832,74.564,66.635,149.128,133.269"></path>
            <path d="M0,0C-15.24,98.832,0,100,0,200"></path>
            <path d="M0,0C-15.24,98.832,-38.268,92.388,-76.537,184.776"></path>
            <path d="M0,0C-15.24,98.832,-66.635,74.564,-133.269,149.128"></path>
            <path d="M0,0C-15.24,98.832,-96.092,27.684,-192.183,55.367"></path>
            <path d="M0,0C-15.24,98.832,-94.388,-33.028,-188.777,-66.056"></path>
            <path d="M0,0C-15.24,98.832,-74.564,-66.635,-149.128,-133.269"></path>
            <path d="M0,0C-15.24,98.832,-30.368,-95.278,-60.735,-190.555"></path>
            <path d="M44.504,-194.986C66.756,-292.478,16.821,-299.528,22.428,-399.371"></path>
            <path d="M44.504,-194.986C66.756,-292.478,33.589,-298.114,44.786,-397.485"></path>
            <path d="M44.504,-194.986C66.756,-292.478,50.252,-295.761,67.002,-394.348"></path>
            <path d="M44.504,-194.986C66.756,-292.478,66.756,-292.478,89.008,-389.971"></path>
            <path d="M44.504,-194.986C66.756,-292.478,83.051,-288.275,110.734,-384.367"></path>
            <path d="M44.504,-194.986C66.756,-292.478,99.084,-283.165,132.112,-377.553"></path>
            <path d="M44.504,-194.986C66.756,-292.478,114.805,-277.164,153.073,-369.552"></path>
            <path d="M149.128,-133.269C223.693,-199.904,145.116,-262.567,193.488,-350.089"></path>
            <path d="M149.128,-133.269C223.693,-199.904,159.61,-254.017,212.813,-338.69"></path>
            <path d="M149.128,-133.269C223.693,-199.904,173.601,-244.668,231.469,-326.224"></path>
            <path d="M149.128,-133.269C223.693,-199.904,187.047,-234.549,249.396,-312.733"></path>
            <path d="M149.128,-133.269C223.693,-199.904,199.904,-223.693,266.539,-298.257"></path>
            <path d="M149.128,-133.269C223.693,-199.904,212.132,-212.132,282.843,-282.843"></path>
            <path d="M149.128,-133.269C223.693,-199.904,223.693,-199.904,298.257,-266.539"></path>
            <path d="M149.128,-133.269C223.693,-199.904,234.549,-187.047,312.733,-249.396"></path>
            <path d="M149.128,-133.269C223.693,-199.904,244.668,-173.601,326.224,-231.469"></path>
            <path d="M149.128,-133.269C223.693,-199.904,254.017,-159.61,338.69,-212.813"></path>
            <path d="M149.128,-133.269C223.693,-199.904,262.567,-145.116,350.089,-193.488"></path>
            <path d="M149.128,-133.269C223.693,-199.904,270.291,-130.165,360.388,-173.553"></path>
            <path d="M149.128,-133.269C223.693,-199.904,277.164,-114.805,369.552,-153.073"></path>
            <path d="M198.036,-27.958C297.054,-41.937,288.275,-83.051,384.367,-110.734"></path>
            <path d="M198.036,-27.958C297.054,-41.937,292.478,-66.756,389.971,-89.008"></path>
            <path d="M198.036,-27.958C297.054,-41.937,295.761,-50.252,394.348,-67.002"></path>
            <path d="M198.036,-27.958C297.054,-41.937,298.114,-33.589,397.485,-44.786"></path>
            <path d="M198.036,-27.958C297.054,-41.937,299.528,-16.821,399.371,-22.428"></path>
            <path d="M198.036,-27.958C297.054,-41.937,300,0,400,0"></path>
            <path d="M149.128,133.269C223.693,199.904,298.114,33.589,397.485,44.786"></path>
            <path d="M149.128,133.269C223.693,199.904,295.761,50.252,394.348,67.002"></path>
            <path d="M149.128,133.269C223.693,199.904,292.478,66.756,389.971,89.008"></path>
            <path d="M149.128,133.269C223.693,199.904,288.275,83.051,384.367,110.734"></path>
            <path d="M149.128,133.269C223.693,199.904,283.165,99.084,377.553,132.112"></path>
            <path d="M149.128,133.269C223.693,199.904,277.164,114.805,369.552,153.073"></path>
            <path d="M149.128,133.269C223.693,199.904,270.291,130.165,360.388,173.553"></path>
            <path d="M149.128,133.269C223.693,199.904,262.567,145.116,350.089,193.488"></path>
            <path d="M149.128,133.269C223.693,199.904,254.017,159.61,338.69,212.813"></path>
            <path d="M149.128,133.269C223.693,199.904,244.668,173.601,326.224,231.469"></path>
            <path d="M149.128,133.269C223.693,199.904,234.549,187.047,312.733,249.396"></path>
            <path d="M149.128,133.269C223.693,199.904,223.693,199.904,298.257,266.539"></path>
            <path d="M149.128,133.269C223.693,199.904,212.132,212.132,282.843,282.843"></path>
            <path d="M149.128,133.269C223.693,199.904,199.904,223.693,266.539,298.257"></path>
            <path d="M149.128,133.269C223.693,199.904,187.047,234.549,249.396,312.733"></path>
            <path d="M149.128,133.269C223.693,199.904,173.601,244.668,231.469,326.224"></path>
            <path d="M149.128,133.269C223.693,199.904,159.61,254.017,212.813,338.69"></path>
            <path d="M149.128,133.269C223.693,199.904,145.116,262.567,193.488,350.089"></path>
            <path d="M149.128,133.269C223.693,199.904,130.165,270.291,173.553,360.388"></path>
            <path d="M149.128,133.269C223.693,199.904,114.805,277.164,153.073,369.552"></path>
            <path d="M149.128,133.269C223.693,199.904,99.084,283.165,132.112,377.553"></path>
            <path d="M149.128,133.269C223.693,199.904,83.051,288.275,110.734,384.367"></path>
            <path d="M149.128,133.269C223.693,199.904,66.756,292.478,89.008,389.971"></path>
            <path d="M0,200C0,300,33.589,298.114,44.786,397.485"></path>
            <path d="M0,200C0,300,16.821,299.528,22.428,399.371"></path>
            <path d="M0,200C0,300,0,300,0,400"></path>
            <path d="M0,200C0,300,-16.821,299.528,-22.428,399.371"></path>
            <path d="M0,200C0,300,-33.589,298.114,-44.786,397.485"></path>
            <path d="M-76.537,184.776C-114.805,277.164,-66.756,292.478,-89.008,389.971"></path>
            <path d="M-76.537,184.776C-114.805,277.164,-83.051,288.275,-110.734,384.367"></path>
            <path d="M-76.537,184.776C-114.805,277.164,-99.084,283.165,-132.112,377.553"></path>
            <path d="M-76.537,184.776C-114.805,277.164,-114.805,277.164,-153.073,369.552"></path>
            <path d="M-76.537,184.776C-114.805,277.164,-130.165,270.291,-173.553,360.388"></path>
            <path d="M-76.537,184.776C-114.805,277.164,-145.116,262.567,-193.488,350.089"></path>
            <path d="M-76.537,184.776C-114.805,277.164,-159.61,254.017,-212.813,338.69"></path>
            <path d="M-133.269,149.128C-199.904,223.693,-187.047,234.549,-249.396,312.733"></path>
            <path d="M-133.269,149.128C-199.904,223.693,-199.904,223.693,-266.539,298.257"></path>
            <path d="M-133.269,149.128C-199.904,223.693,-212.132,212.132,-282.843,282.843"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-234.549,187.047,-312.733,249.396"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-244.668,173.601,-326.224,231.469"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-254.017,159.61,-338.69,212.813"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-262.567,145.116,-350.089,193.488"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-270.291,130.165,-360.388,173.553"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-277.164,114.805,-369.552,153.073"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-283.165,99.084,-377.553,132.112"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-288.275,83.051,-384.367,110.734"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-292.478,66.756,-389.971,89.008"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-295.761,50.252,-394.348,67.002"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-298.114,33.589,-397.485,44.786"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-299.528,16.821,-399.371,22.428"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-300,0,-400,0"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-299.528,-16.821,-399.371,-22.428"></path>
            <path d="M-192.183,55.367C-288.275,83.051,-298.114,-33.589,-397.485,-44.786"></path>
            <path d="M-188.777,-66.056C-283.165,-99.084,-292.478,-66.756,-389.971,-89.008"></path>
            <path d="M-188.777,-66.056C-283.165,-99.084,-288.275,-83.051,-384.367,-110.734"></path>
            <path d="M-188.777,-66.056C-283.165,-99.084,-283.165,-99.084,-377.553,-132.112"></path>
            <path d="M-188.777,-66.056C-283.165,-99.084,-277.164,-114.805,-369.552,-153.073"></path>
            <path d="M-188.777,-66.056C-283.165,-99.084,-270.291,-130.165,-360.388,-173.553"></path>
            <path d="M-149.128,-133.269C-223.693,-199.904,-254.017,-159.61,-338.69,-212.813"></path>
            <path d="M-149.128,-133.269C-223.693,-199.904,-244.668,-173.601,-326.224,-231.469"></path>
            <path d="M-149.128,-133.269C-223.693,-199.904,-234.549,-187.047,-312.733,-249.396"></path>
            <path d="M-149.128,-133.269C-223.693,-199.904,-223.693,-199.904,-298.257,-266.539"></path>
            <path d="M-149.128,-133.269C-223.693,-199.904,-212.132,-212.132,-282.843,-282.843"></path>
            <path d="M-149.128,-133.269C-223.693,-199.904,-199.904,-223.693,-266.539,-298.257"></path>
            <path d="M-149.128,-133.269C-223.693,-199.904,-187.047,-234.549,-249.396,-312.733"></path>
            <path d="M-60.735,-190.555C-91.103,-285.833,-159.61,-254.017,-212.813,-338.69"></path>
            <path d="M-60.735,-190.555C-91.103,-285.833,-145.116,-262.567,-193.488,-350.089"></path>
            <path d="M-60.735,-190.555C-91.103,-285.833,-130.165,-270.291,-173.553,-360.388"></path>
            <path d="M-60.735,-190.555C-91.103,-285.833,-114.805,-277.164,-153.073,-369.552"></path>
            <path d="M-60.735,-190.555C-91.103,-285.833,-99.084,-283.165,-132.112,-377.553"></path>
            <path d="M-60.735,-190.555C-91.103,-285.833,-83.051,-288.275,-110.734,-384.367"></path>
            <path d="M-60.735,-190.555C-91.103,-285.833,-66.756,-292.478,-89.008,-389.971"></path>
            <path d="M-60.735,-190.555C-91.103,-285.833,-50.252,-295.761,-67.002,-394.348"></path>
            <path d="M-60.735,-190.555C-91.103,-285.833,-33.589,-298.114,-44.786,-397.485"></path>
            <path d="M-60.735,-190.555C-91.103,-285.833,-16.821,-299.528,-22.428,-399.371"></path>
          </g>
          <g>
            <circle transform="rotate(98.76623376623377) translate(0,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(-77.14285714285714) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(-41.785714285714285) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(-8.035714285714292) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(41.78571428571428) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(90) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(112.49999999999997) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(131.7857142857143) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(163.92857142857144) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(199.28571428571428) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(221.78571428571433) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(252.3214285714286) translate(200,0)" fill="#555" r="2.5"></circle>
            <circle transform="rotate(-86.78571428571429) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-83.57142857142857) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-80.35714285714286) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-77.14285714285714) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-73.92857142857143) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-70.71428571428571) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-67.5) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-61.07142857142857) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-57.85714285714286) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-54.642857142857146) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-51.42857142857142) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-48.214285714285715) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-45) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-41.785714285714285) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-38.57142857142857) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-35.35714285714287) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-32.142857142857146) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-28.92857142857143) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-25.714285714285722) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-22.5) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-16.07142857142857) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-12.857142857142847) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-9.642857142857139) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-6.428571428571431) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(-3.214285714285708) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(0) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(6.428571428571431) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(9.642857142857139) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(12.857142857142861) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(16.07142857142857) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(19.285714285714263) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(22.5) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(25.714285714285708) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(28.92857142857143) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(32.14285714285714) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(35.35714285714285) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(38.571428571428555) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(41.78571428571428) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(45) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(48.214285714285694) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(51.428571428571416) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(54.64285714285714) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(57.85714285714286) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(61.071428571428555) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(64.2857142857143) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(67.5) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(70.71428571428572) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(73.92857142857142) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(77.14285714285714) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(83.57142857142858) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(86.78571428571428) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(90) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(93.2142857142857) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(96.42857142857147) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(102.85714285714286) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(106.07142857142856) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(109.28571428571428) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(112.49999999999997) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(115.71428571428572) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(118.92857142857144) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(122.14285714285714) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(128.57142857142853) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(131.7857142857143) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(135) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(141.42857142857142) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(144.64285714285717) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(147.85714285714286) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(151.07142857142856) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(154.28571428571428) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(157.49999999999997) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(160.7142857142857) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(163.92857142857144) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(167.1428571428571) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(170.35714285714283) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(173.57142857142856) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(176.78571428571428) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(180) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(183.21428571428567) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(186.4285714285714) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(192.85714285714283) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(196.07142857142856) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(199.28571428571428) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(202.50000000000006) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(205.71428571428572) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(212.1428571428571) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(215.35714285714283) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(218.5714285714286) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(221.78571428571433) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(225) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(228.21428571428567) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(231.42857142857144) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(237.85714285714283) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(241.07142857142856) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(244.28571428571428) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(247.49999999999994) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(250.71428571428567) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(253.92857142857144) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(257.14285714285717) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(260.35714285714283) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(263.57142857142856) translate(400,0)" fill="#999" r="2.5"></circle>
            <circle transform="rotate(266.7857142857143) translate(400,0)" fill="#999" r="2.5"></circle>
          </g>
          <g>
            <image></image>
            <image></image>
            <image></image>
            <image></image>
            <image></image>
            <image></image>
            <image></image>
            <image></image>
            <image></image>
            <image></image>
            <image></image>
            <image></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/3dfy-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-86.78571428571429) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/artefacts-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-83.57142857142857) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/csm-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-80.35714285714286) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/luma-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-77.14285714285714) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/meshy-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-73.92857142857143) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/spline-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-70.71428571428571) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/tripo3d.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-67.5) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/coze.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-61.07142857142857) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/dify.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-57.85714285714286) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/kimi.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-54.642857142857146) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wan-zhi.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-51.42857142857142) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/shang-liang.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-48.214285714285715) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wen-xin-yi-yan.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-45) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/xing-huo.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-41.785714285714285) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-38.57142857142857) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/hun-yuan.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-35.35714285714287) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/bai-chuan.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-32.142857142857146) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/dou-bao.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-28.92857142857143) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/yue-wen.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-25.714285714285722) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/tong-yi-qian-wen.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-22.5) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-16.07142857142857) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/claude.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-12.857142857142847) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gemini.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-9.642857142857139) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/grok-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-6.428571428571431) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/llama-hugging-face-space.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(-3.214285714285708) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/mistral.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(0) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/blockadelabs.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(6.428571428571431) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/civitai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(9.642857142857139) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/clipdrop.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(12.857142857142861) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/Code-Former.jpg" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(16.07142857142857) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/dall-e-copilot-designer.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(19.285714285714263) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/deep-dream-generator.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(22.5) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/diffus.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(25.714285714285708) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/dreamstudio.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(28.92857142857143) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/dreamina.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(32.14285714285714) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/firefly.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(35.35714285714285) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/freepic-pikaso.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(38.571428571428555) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gemini.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(41.78571428571428) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/imgpilot.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(45) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/krea-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(48.214285714285694) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/latent-consistency-fal-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(51.428571428571416) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/Leonardo.jpg" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(54.64285714285714) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/lexica.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(57.85714285714286) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/liblibai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(61.071428571428555) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/magnific.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(64.2857142857143) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/midjourney.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(67.5) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/openart.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(70.71428571428572) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/vega-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(73.92857142857142) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wu-jie-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(77.14285714285714) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/devv-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(83.57142857142858) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/globe-explorer.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(86.78571428571428) translate(400,0) rotate(0)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/perplexity.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(90) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/tian-gong-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(93.2142857142857) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/mi-ta-ai-sou-suo.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(96.42857142857147) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/d-id.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(102.85714285714286) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/heygen.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(106.07142857142856) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/kalidoface.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(109.28571428571428) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/raskai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(112.49999999999997) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/synthesia.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(115.71428571428572) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/shang-tang-ru-ying.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(118.92857142857144) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/xun-fei-zhi-zuo.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(122.14285714285714) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/1024code.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(128.57142857142853) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/cursor.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(131.7857142857143) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/github-copilot.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(135) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/stable-video-diffusion-replicate.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(141.42857142857142) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/domo-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(144.64285714285717) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/kaiber.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(147.85714285714286) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/moonvalley.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(151.07142857142856) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/mootion.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(154.28571428571428) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/morph.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(157.49999999999997) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/pika.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(160.7142857142857) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/pixverse.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(163.92857142857144) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/runway.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(167.1428571428571) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/openai-voice-engine.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(170.35714285714283) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/latent-consistency-fal-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(173.57142857142856) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/stable-video-diffusion-replicate.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(176.78571428571428) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/viggle.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(180) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/jian-ying-dreamina.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(183.21428571428567) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/du-jia.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(186.4285714285714) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/beautiful-ai.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(192.85714285714283) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/canva.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(196.07142857142856) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gamma.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(199.28571428571428) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/tome.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(202.50000000000006) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wan-zhi.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(205.71428571428572) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/elevenlabs.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(212.1428571428571) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/openai-voice-engine.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(215.35714285714283) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/jian-ying-ai-ke-lun-yin-se.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(218.5714285714286) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/da-bing-ai-bian-sheng.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(221.78571428571433) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zi-jie-huo-shan-yin.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(225) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wei-ruan-yu-yin.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(228.21428571428567) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/xun-fei-zhi-zuo.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(231.42857142857144) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/ace-studio.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(237.85714285714283) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/aiva.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(241.07142857142856) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/mubert.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(244.28571428571428) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/suno.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(247.49999999999994) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/stable-audio.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(250.71428571428567) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/x-studio.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(253.92857142857144) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/udio.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(257.14285714285717) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/kou-dai-yue-dui-app.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(260.35714285714283) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/he-xian-pai-app.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(263.57142857142856) translate(400,0) rotate(180)" fill="#999"></image>
            <image xlink:href="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wang-yi-tian-yin.webp" x="-10" y="-10" width="20" height="20" clip-path="inset(0% round 15px)" transform="rotate(266.7857142857143) translate(400,0) rotate(180)" fill="#999"></image>
          </g>
          <g stroke-linejoin="round" stroke-width="3">
            <text transform="rotate(98.76623376623377) translate(0,0) rotate(180)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">精选 AI 产品集</text>
            <text transform="rotate(-77.14285714285714) translate(200,0) rotate(0)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">3D</text>
            <text transform="rotate(-41.785714285714285) translate(200,0) rotate(0)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">LLM（中国）</text>
            <text transform="rotate(-8.035714285714292) translate(200,0) rotate(0)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">LLM（全球）</text>
            <text transform="rotate(41.78571428571428) translate(200,0) rotate(0)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">图像</text>
            <text transform="rotate(90) translate(200,0) rotate(180)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">搜索</text>
            <text transform="rotate(112.49999999999997) translate(200,0) rotate(180)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">数字人</text>
            <text transform="rotate(131.7857142857143) translate(200,0) rotate(180)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">编程</text>
            <text transform="rotate(163.92857142857144) translate(200,0) rotate(180)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">视频</text>
            <text transform="rotate(199.28571428571428) translate(200,0) rotate(180)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">设计</text>
            <text transform="rotate(221.78571428571433) translate(200,0) rotate(180)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">语音</text>
            <text transform="rotate(252.3214285714286) translate(200,0) rotate(180)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">音乐</text>
            <text transform="rotate(-86.78571428571429) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">3DFY AI</text>
            <text transform="rotate(-83.57142857142857) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Artefacts AI</text>
            <text transform="rotate(-80.35714285714286) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">CSM AI</text>
            <text transform="rotate(-77.14285714285714) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Luma AI</text>
            <text transform="rotate(-73.92857142857143) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Meshy AI</text>
            <text transform="rotate(-70.71428571428571) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Spline AI</text>
            <text transform="rotate(-67.5) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Tripo3D</text>
            <text transform="rotate(-61.07142857142857) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Coze</text>
            <text transform="rotate(-57.85714285714286) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Dify</text>
            <text transform="rotate(-54.642857142857146) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Kimi</text>
            <text transform="rotate(-51.42857142857142) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">万知</text>
            <text transform="rotate(-48.214285714285715) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">商量</text>
            <text transform="rotate(-45) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">文心一言</text>
            <text transform="rotate(-41.785714285714285) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">星火</text>
            <text transform="rotate(-38.57142857142857) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">智谱清言</text>
            <text transform="rotate(-35.35714285714287) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">混元</text>
            <text transform="rotate(-32.142857142857146) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">百川</text>
            <text transform="rotate(-28.92857142857143) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">豆包</text>
            <text transform="rotate(-25.714285714285722) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">跃问</text>
            <text transform="rotate(-22.5) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">通义千问</text>
            <text transform="rotate(-16.07142857142857) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">ChatGPT</text>
            <text transform="rotate(-12.857142857142847) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Claude</text>
            <text transform="rotate(-9.642857142857139) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Gemini</text>
            <text transform="rotate(-6.428571428571431) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Grok xAI</text>
            <text transform="rotate(-3.214285714285708) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Llama (Hugging Face Space)</text>
            <text transform="rotate(0) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Mistral</text>
            <text transform="rotate(6.428571428571431) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Blockadelabs</text>
            <text transform="rotate(9.642857142857139) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Civitai</text>
            <text transform="rotate(12.857142857142861) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Clipdrop</text>
            <text transform="rotate(16.07142857142857) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Code Former</text>
            <text transform="rotate(19.285714285714263) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">DALL-E (Copilot Designer)</text>
            <text transform="rotate(22.5) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Deep Dream Generator</text>
            <text transform="rotate(25.714285714285708) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Diffus</text>
            <text transform="rotate(28.92857142857143) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">DreamStudio</text>
            <text transform="rotate(32.14285714285714) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Dreamina</text>
            <text transform="rotate(35.35714285714285) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Firefly</text>
            <text transform="rotate(38.571428571428555) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Freepic Pikaso</text>
            <text transform="rotate(41.78571428571428) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Gemini</text>
            <text transform="rotate(45) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">ImgPilot</text>
            <text transform="rotate(48.214285714285694) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Krea.ai</text>
            <text transform="rotate(51.428571428571416) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Latent Consistency (Fal.ai)</text>
            <text transform="rotate(54.64285714285714) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Leonardo</text>
            <text transform="rotate(57.85714285714286) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Lexica</text>
            <text transform="rotate(61.071428571428555) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">LibLibAI</text>
            <text transform="rotate(64.2857142857143) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Magnific</text>
            <text transform="rotate(67.5) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Midjourney</text>
            <text transform="rotate(70.71428571428572) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">OpenArt</text>
            <text transform="rotate(73.92857142857142) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Vega AI</text>
            <text transform="rotate(77.14285714285714) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Wujie AI</text>
            <text transform="rotate(83.57142857142858) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Devv AI</text>
            <text transform="rotate(86.78571428571428) translate(410,0) rotate(0)" dy="0.31em" x="6" text-anchor="start" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Globe Explorer</text>
            <text transform="rotate(90) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Perplexity</text>
            <text transform="rotate(93.2142857142857) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">天工 AI</text>
            <text transform="rotate(96.42857142857147) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">秘塔 AI 搜索</text>
            <text transform="rotate(102.85714285714286) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">D-iD</text>
            <text transform="rotate(106.07142857142856) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">HeyGen</text>
            <text transform="rotate(109.28571428571428) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Kalidoface</text>
            <text transform="rotate(112.49999999999997) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">RaskAI</text>
            <text transform="rotate(115.71428571428572) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Synthesia</text>
            <text transform="rotate(118.92857142857144) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">商汤如影</text>
            <text transform="rotate(122.14285714285714) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">讯飞智作</text>
            <text transform="rotate(128.57142857142853) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">1024 Code</text>
            <text transform="rotate(131.7857142857143) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Cursor</text>
            <text transform="rotate(135) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Github Copilot</text>
            <text transform="rotate(141.42857142857142) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">AnimateDiff (replicate)</text>
            <text transform="rotate(144.64285714285717) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Domo AI</text>
            <text transform="rotate(147.85714285714286) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Kaiber</text>
            <text transform="rotate(151.07142857142856) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Moonvalley</text>
            <text transform="rotate(154.28571428571428) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Mootion</text>
            <text transform="rotate(157.49999999999997) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Morph Studio</text>
            <text transform="rotate(160.7142857142857) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Pika</text>
            <text transform="rotate(163.92857142857144) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Pixverse</text>
            <text transform="rotate(167.1428571428571) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Runway</text>
            <text transform="rotate(170.35714285714283) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Sora</text>
            <text transform="rotate(173.57142857142856) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Stable Video Diffusion (Fal.ai)</text>
            <text transform="rotate(176.78571428571428) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Stable Video Diffusion (replicate)</text>
            <text transform="rotate(180) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Viggle</text>
            <text transform="rotate(183.21428571428567) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">剪映 Dreamina</text>
            <text transform="rotate(186.4285714285714) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">度加</text>
            <text transform="rotate(192.85714285714283) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Beautiful.ai</text>
            <text transform="rotate(196.07142857142856) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Canva</text>
            <text transform="rotate(199.28571428571428) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Gamma</text>
            <text transform="rotate(202.50000000000006) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Tome</text>
            <text transform="rotate(205.71428571428572) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">万知</text>
            <text transform="rotate(212.1428571428571) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">ElevenLabs</text>
            <text transform="rotate(215.35714285714283) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">OpenAI Voice Engine</text>
            <text transform="rotate(218.5714285714286) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">剪映 AI 克隆音色</text>
            <text transform="rotate(221.78571428571433) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">大饼 AI 变声</text>
            <text transform="rotate(225) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">字节火山语音</text>
            <text transform="rotate(228.21428571428567) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">微软语音</text>
            <text transform="rotate(231.42857142857144) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">讯飞智作</text>
            <text transform="rotate(237.85714285714283) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">ACE Studio</text>
            <text transform="rotate(241.07142857142856) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">AIVA</text>
            <text transform="rotate(244.28571428571428) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Mubert</text>
            <text transform="rotate(247.49999999999994) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">SUNO</text>
            <text transform="rotate(250.71428571428567) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">Stable Audio</text>
            <text transform="rotate(253.92857142857144) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">X Studio</text>
            <text transform="rotate(257.14285714285717) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">udio</text>
            <text transform="rotate(260.35714285714283) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">口袋乐队 app</text>
            <text transform="rotate(263.57142857142856) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">和弦派 app</text>
            <text transform="rotate(266.7857142857143) translate(410,0) rotate(180)" dy="0.31em" x="-6" text-anchor="end" paint-order="stroke" stroke="hsl(var(--background))" fill="currentColor">网易天音</text>
            </g>
        </svg>
      </div>

      <div class="ai-module">
        <div>
          <h1 class="ai-title">LLM（全球）</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">ChatGPT</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gemini.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Gemini</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/claude.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Claude</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/mistral.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Mistral</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/llama-hugging-face-space.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Llama (Hugging Face Space)</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/grok-ai.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Grok xAI</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div class="ai-module mt-50">
        <div>
          <h1 class="ai-title">LLM（中国）</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">智谱清言</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/dou-bao.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">豆包</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/xing-huo.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">星火</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/kimi.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Kimi</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/coze.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Coze</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/bai-chuan.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">百川</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wen-xin-yi-yan.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">文心一言</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/tong-yi-qian-wen.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">通义千问</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/hun-yuan.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">混元</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/shang-liang.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">商量</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/yue-wen.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">跃问</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wan-zhi.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">万知</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/dify.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Dify</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <div class="ai-module mt-50">
        <div>
          <h1 class="ai-title">搜索</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/perplexity.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Perplexity</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/mi-ta-ai-sou-suo.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">秘塔 AI 搜索</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/globe-explorer.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Globe Explorer</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/tian-gong-ai.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">天工 AI</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/devv-ai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Devv AI</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <div class="ai-module mt-50">
        <div>
          <h1 class="ai-title">图像</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/dall-e-copilot-designer.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">DALL-E (Copilot Designer)</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/midjourney.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Midjourney</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/dreamstudio.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">DreamStudio</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/clipdrop.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Clipdrop</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/krea-ai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Krea.ai</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/freepic-pikaso.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Freepic Pikaso</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/imgpilot.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">ImgPilot</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/latent-consistency-fal-ai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Latent Consistency (Fal.ai)</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/blockadelabs.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Blockadelabs</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/deep-dream-generator.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Deep Dream Generator</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/magnific.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Magnific</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/civitai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Civitai</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/openart.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">OpenArt</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/liblibai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">LibLibAI</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/vega-ai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Vega AI</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wu-jie-ai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Wujie AI</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/dreamina.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Dreamina</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/firefly.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Firefly</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gemini.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Gemini</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/diffus.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Diffus</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/Code-Former.jpg"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Code Former</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/lexica.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Lexica</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/Leonardo.jpg"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Leonardo</span>
              </div>
            </el-card>
          </el-col>
          
        </el-row>
      </div>

      <div class="ai-module mt-50">
        <div>
          <h1 class="ai-title">视频</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/openai-voice-engine.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Sora</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/runway.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Runway</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/morph.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Morph Studio</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/pika.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Pika</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/pixverse.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Pixverse</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/moonvalley.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Moonvalley</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/mootion.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Mootion</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/domo-ai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Domo AI</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/latent-consistency-fal-ai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Stable Video Diffusion (Fal.ai)</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/stable-video-diffusion-replicate.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Stable Video Diffusion (replicate)</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/stable-video-diffusion-replicate.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">AnimateDiff (replicate)</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/kaiber.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Kaiber</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/jian-ying-dreamina.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">剪映 Dreamina</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/du-jia.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">度加</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/viggle.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Viggle</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <div class="ai-module mt-50">
        <div>
          <h1 class="ai-title">音乐</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/udio.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">udio</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/suno.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">SUNO</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/stable-audio.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Stable Audio</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/mubert.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Mubert</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/kou-dai-yue-dui-app.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">口袋乐队 app</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/he-xian-pai-app.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">和弦派 app</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/x-studio.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">X Studio</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/ace-studio.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">ACE Studio</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wang-yi-tian-yin.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">网易天音</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/aiva.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">AIVA</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <div class="ai-module mt-50">
        <div>
          <h1 class="ai-title">语音</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/elevenlabs.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">ElevenLabs</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/xun-fei-zhi-zuo.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">讯飞智作</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/jian-ying-ai-ke-lun-yin-se.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">剪映 AI 克隆音色</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zi-jie-huo-shan-yin.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">字节火山语音</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wei-ruan-yu-yin.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">微软语音</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/openai-voice-engine.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">OpenAI Voice Engine</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/da-bing-ai-bian-sheng.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">大饼 AI 变声</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <div class="ai-module mt-50">
        <div>
          <h1 class="ai-title">数字人</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/heygen.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">HeyGen</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/raskai.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">RaskAI</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/d-id.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">D-iD</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/xun-fei-zhi-zuo.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">讯飞智作</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/shang-tang-ru-ying.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">商汤如影</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/synthesia.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Synthesia</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/kalidoface.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Kalidoface</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <div class="ai-module mt-50">
        <div>
          <h1 class="ai-title">3D</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/tripo3d.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Tripo3D</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/meshy-ai.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Meshy AI</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/artefacts-ai.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Artefacts AI</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/csm-ai.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">CSM AI</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/3dfy-ai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">3DFY AI</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/luma-ai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Luma AI</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/spline-ai.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Spline AI</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <div class="ai-module mt-50">
        <div>
          <h1 class="ai-title">设计</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/tome.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Tome</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gamma.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Gamma</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/beautiful-ai.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Beautiful.ai</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/wan-zhi.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">万知</span>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/canva.webp"
                  :size="70" class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Canva</span>
              </div>
            </el-card>
          </el-col>

        </el-row>
      </div>

      <div class="ai-module mt-50" style="margin-bottom:50px;">
        <div>
          <h1 class="ai-title">编程</h1>
        </div>
        <el-row :gutter="20">
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/github-copilot.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Github Copilot</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/cursor.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">Cursor</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6" class="mt-20">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/1024code.webp" :size="70"
                  class="avatar-border" />
                <span class="mt-15 fw-500" style="font-size:14px;">1024 Code</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'AiSet',
  setup() {
  }
}
</script>

<style>
#ai-set {
  min-height: 100vh;
}

.ai-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.avatar-border {
  border: 1px solid #e5e7eb;
}

.ai-title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.ai-module {
  margin-left:40px;
  text-align:left;
  width:1180px;
}

.set-title {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
}
</style>
