<template>
  <div>
    <el-row class="ai-header">
      <el-col :span="4" :class="['module-column', moduleFlag=='/'?'module-active':'' ]" @click="selectAiModule('/')">
        <div class="flex flex-center">
          <el-icon :color="moduleFlag=='/'?'#fff':'#2775ff'" ><Grid /></el-icon>
          <span class="ml-10">全部</span>
        </div>
      </el-col>
      <el-col :span="4" :class="['module-column', moduleFlag=='/chatm'?'module-active':'' ]" @click="selectAiModule('/chatm')">
        <div class="flex flex-center">
          <el-icon :color="moduleFlag=='/chatm'?'#fff':'#409EFF'" ><ChatDotSquare /></el-icon>
          <span class="ml-10">对话专区</span>
        </div>
      </el-col>
      <el-col :span="4" class="module-column">
        <div class="flex flex-center">
          <el-icon color="#2775ff"><MagicStick /></el-icon>
          <span class="ml-10">绘画专区</span>
        </div>
      </el-col>
      <el-col :span="4" class="module-column">
        <div class="flex flex-center">
          <el-icon color="#67C23A"><Medal /></el-icon>
          <span class="ml-10">学术专区</span>
        </div>
      </el-col>
      <el-col :span="4" class="module-column">
        <div class="flex flex-center">
          <el-icon color="#E6A23C"><EditPen /></el-icon>
          <span class="ml-10">论文写作</span>
        </div>
      </el-col>
      <el-col :span="4" class="module-column">
        <div class="flex flex-center">
          <el-icon color="#F56C6C"><Suitcase /></el-icon>
          <span class="ml-10">职场专区</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { ref } from 'vue';
import { useRouter,useRoute } from 'vue-router';

export default {
  setup() {

    const route = useRoute()
    const router = useRouter();
    const moduleFlag = route.fullPath;

    const selectAiModule = (i) => {
      if (moduleFlag == i) {
        return;
      }

      if (i == '/') {
        router.push({  name: 'AiIndex'});
      }
      if (i == '/chatm') {
        router.push({  name: 'ChatModule'});
      }

      
    }
    return {
      moduleFlag,
      selectAiModule
    }
  }
}
</script>

<style scoped>
.ai-header {
  width:100%;
  display:flex;
  box-shadow: 5px 4px 6px #edf3ff, 5px 8px 12px #edf3ff;
}

.module-column {
  height:50px;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #3b4250;
  cursor: pointer;
  border-right: 2px solid #dcdfe6;
  font-weight: bold;
}
.module-active {
  color:#fff;
  background-color: var(--el-color-primary) !important;
}
</style>