<!-- src/views/Home.vue -->
<template>
  <el-container>
    <el-aside width="260px" class="chat-list">
      <div style="height:20px;"></div>
      <div style="padding:0 13px;">
        <div class="text-left" style="padding:0px 8px;">
          <h3 style="color:#7d7d7d;font-weight: 600;font-size: .75rem;line-height: 1rem;">最近七天</h3>
        </div>
        <div style="text-align:left;color:#0d0d0d;font-size:14px;padding: 8px;">对话一</div>
        <div style="text-align:left;color:#0d0d0d;font-size:14px;padding: 8px;border-radius:6px;background-color:#ececec;">对话二</div>
      </div>
    </el-aside>
    <el-main style="padding:0px;">
        <div style="min-height:90vh;">
          <div class="flex flex-align-center fs-12 space-between" style="background-color:#f7f7f8;color:#3d3f3f;height:24px;padding:6px 16px;">
            <router-link to="/">
              <div style="color:#3d3f3f;text-decoration: none;">首页</div>
            </router-link>
            <div style='font-size:18px;font-weight: 400;'>{{aiVersionName}}</div>
          </div>
          <div class="chat-box" ref="msgContainerRef">
            <div class="messages" >
              <div v-for="(message, index) in messages" :key="index" :class="message.role == 'user' ? 'user-info' : 'assistant-info'"
                style="display:flex;margin:28px 0;">
                <div v-if="message.role == 'user'" style="display: flex;flex-direction: column;align-items: flex-end;">
                  <div class="chat-extra" v-if="message.extra">
                    <el-icon style="color:#f57373;"><Document /></el-icon> 
                    <span class="ml-5">{{ message.extra }}</span>
                  </div>
                  <div class="chat-content" >{{ message.content }}</div>
                </div>
                <div class="chat-content-assistant" v-if="message.role == 'assistant'">
                  <el-image class="ai-robot-img"
                    :src="aiRobotAvatar"  />
                  <div style="width:700px;">
<!--                    <div style="font-size:14px;">{{message.content}}</div>-->
                     <v-md-preview :text="message.content" style="padding:0px;"></v-md-preview>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-affix position="bottom" :offset="40">
          <div style="width:760px;margin:0 auto;">
            <div class="flex space-between flex-center">
              <div style="padding:4px 2px;border:1px solid #F0F0F0;width:120px;border-radius:6px;margin-bottom:4px;" v-if="ai_type=='zhipuai'||ai_type=='tyqw'">
                <el-switch v-model="internetFlag" size="small"/>
                <span class="fs-12">互联网检索</span>
              </div>
              <div class="flex flex-center" v-if="fileUploadName">
                <el-icon v-if="fileLoading" class="is-loading color-main"><Loading /> </el-icon>
                <el-icon v-else style="color:#67C23A;"><Check /></el-icon>
                <el-icon style="color:#f57373;"><Document /></el-icon> 
                <span class="ml-5 fs-14">{{fileUploadName}}</span>
              </div>
            </div>
            
            <div class="search-input" :class="{ focused: isFocused }">
              <div class="flex flex-center">
                <!-- <div class="flex flex-center " style="">
                  <el-icon><Expand /></el-icon>
                </div> -->
                <div class="flex flex-center batch-info" v-if = "ai_type=='tyqw'">
                  <el-popover
                      :width="140"
                      popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;margin-top:20px;"
                      trigger="click"
                  >
                    <template #reference>
                      <el-icon class="cursor-pointer"><Expand /></el-icon>
                    </template>
                    <template #default style="margin-top:20px;">
                      <div style="display: flex; gap: 16px; flex-direction: column;justify-content: center;">
                        <el-tooltip
                          class="box-item"
                          effect="light"
                          content="支持PDF / Word / Markdown /EPUB / Mobi / txt"
                          placement="right"
                        >
                          <div class="search-extra-list" @click="triggerFileUploadInput">
                            <el-icon><Upload /></el-icon>
                            <span class="ml-10 fw-bold">文件解读</span>
                          </div>
                        </el-tooltip>
                        
                      </div>
                    </template>
                  </el-popover>
                </div>
                <input v-model="newMessage" @keyup.enter="sendMessage" type="text" class="no-border" style="padding:10px;width:740px;font-size:14px;" placeholder="请输入问题" @focus="handleFocus" @blur="handleBlur">
                <div class="flex flex-center ml-10">
                  <el-image style="width: 32px; height: 32px"
                  src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/arrow-circle-up.png" @click="sendMessage"/>
                </div>
              </div>
            </div>
          </div>
        </el-affix>
    </el-main>
  </el-container>
  <input type="file" ref="fileUploadInput" @change="handleUploadFileChange" style="display: none"/>
</template>

<script>
import {ref, nextTick, reactive, FatalError} from 'vue';
import { fetchEventSource } from '@microsoft/fetch-event-source';



// import ChatBox from '@/components/ChatBox.vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { ElMessage } from 'element-plus'


export default {
  name: 'Chat',
  
  setup() {
    const route = useRoute();
    const ai_type = route.query.ai_type;
    const engineType = route.query.engine_type;
    const askContent = route.query.content;

    const internetFlag = ref(false);
    const messages = ref([]);
    const newMessage = ref('');
    const msgContainerRef = ref(null);
    const aiVersionName = ref('');
    const fileLoading = ref(true);
    const fileUploadInput = ref(null);
    const fileUploadName = ref("");

    // const aiRobotAvatar = ref('https://registry.npmmirror.com/@lobehub/fluent-emoji-3d/1.1.0/files/assets/1f92f.webp');
    const aiRobotAvatar = ref('https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png');

    if (ai_type == 'zhipuai') {
      aiVersionName.value = '智谱AI';
    }

    if (ai_type == 'kimi') {
      messages.value.push({
        "role": "system", 
        "content": "你是 Kimi，由 Moonshot AI 提供的人工智能助手，你更擅长中文和英文的对话。你会为用户提供安全，有帮助，准确的回答。同时，你会拒绝一切涉及恐怖主义，种族歧视，黄色暴力等问题的回答。Moonshot AI 为专有名词，不可翻译成其他语言。"});
      aiVersionName.value = 'Kimi.ai';
    }

    if (ai_type == 'tyqw') {
      aiVersionName.value = '通义千问';
    }

    if (ai_type === 'chatgpt') {
      aiVersionName.value = 'ChatGPT';
      if (engineType === 'gpt-4o-2024-08-06') {
        aiVersionName.value = 'ChatGPT-4o-2024-08-06';
      }

      if (engineType === 'gpt-4o') {
        aiVersionName.value = 'ChatGPT-4o';
      }

      if (engineType === 'gpt-4-turbo') {
        aiVersionName.value = 'ChatGPT-4-turbo';
      }

      if (engineType === 'gpt-4o-mini') {
        aiVersionName.value = 'ChatGPT-4o-mini';
      }

      if (engineType === 'gpt-3.5-turbo') {
        aiVersionName.value = 'ChatGPT-3.5-turbo';
      }

      if (engineType === 'gpt-3.5-turbo-instruct') {
        aiVersionName.value = 'ChatGPT-3.5-turbo-instruct';
      }

      if (engineType === 'claude-3.5-sonnet') {
        aiVersionName.value = 'Claude-3.5-Sonnet';
      }

      if (engineType === 'llama-3.1-405b') {
        aiVersionName.value = 'LLama-3.1-405b';
      }

      if (engineType === 'llama-3.1-70b') {
        aiVersionName.value = 'LLama-3.1-70b';
      }

      if (engineType === 'llama-3-70b') {
        aiVersionName.value = 'LLama-3-70b';
      }

    }
    
    const zhiPuAi = async () => {
        let new_value = {
          "role": "user",
          "content": newMessage.value
        }
        messages.value.push(new_value);
        nextTick(()=>{
          scrollBottom();
        });
        newMessage.value = '';

        const ctrl = new AbortController();
        
        fetchEventSource('https://open.bigmodel.cn/api/paas/v4/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1284f7b0066430b8cd1525da410773f0.F7Y5GrelvEFz29sb'
            },
            body: JSON.stringify({
              "model": "glm-4-0520",
              "stream": true,
              "messages": messages.value,
              "tools": [
                {
                  "type": "web_search",
                  "web_search": {
                    "enable": internetFlag.value,
                  }
                }]
            }),
            signal: ctrl.signal,
            onmessage(msg) {
              if (msg.event == 'FatalError') {
                throw new FatalError(msg.data);
              }

              if (msg.event == 'close') {
                ctrl.abort()
              }

              if (msg.event == '') {
                try {
                  tmpContent.content = tmpContent.content.replace(/⚫$/, "");
                  if (msg.data == '[DONE]') {
                    ctrl.abort()
                    return;
                  }
                  
                  const data = JSON.parse(msg.data);
                  tmpContent.content +=  data.choices[0].delta.content+'⚫';
                  nextTick(()=>{
                    scrollBottom();
                  });
                } catch (err) {
                  console.log(err)
                  throw err;
                }
              }
              
            }
        });

        let tmpContent = reactive({
          "role": "assistant",
          "content": "⚫"
        });
        messages.value.push(tmpContent);
    }

    const kimiAi = async () => {
        let new_value = {
          "role": "user",
          "content": newMessage.value
        }
        messages.value.push(new_value);
        nextTick(()=>{
          scrollBottom();
        });
        newMessage.value = '';

        let tmpContent = reactive({
          "role": "assistant",
          "content": "⚫"
        });
        messages.value.push(tmpContent);

        const ctrl = new AbortController();
        
        fetchEventSource('https://api.moonshot.cn/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer sk-jMHmlGrDd8bhd6ZiggPXEenVmib2SgQsTCbhhGjfM8pMNKxA'
            },
            body: JSON.stringify({
              "model": "moonshot-v1-8k",
              "stream": true,
              "messages": messages.value,
              "temperature": 0.3,
            }),
            signal: ctrl.signal,
            onmessage(msg) {
              if (msg.event == 'FatalError') {
                throw new FatalError(msg.data);
              }

              if (msg.event == 'close') {
                ctrl.abort()
              }

              if (msg.event == '') {
                try {
                  tmpContent.content = tmpContent.content.replace(/⚫$/, "");
                  if (msg.data == '[DONE]') {
                    ctrl.abort()
                    return;
                  }
                  const data = JSON.parse(msg.data);
                  if (data.choices[0].delta.content != undefined) {
                    tmpContent.content +=  data.choices[0].delta.content+'⚫';
                  }
                  nextTick(()=>{
                    scrollBottom();
                  });
                } catch (err) {
                  console.log(err)
                  throw err;
                }
              }
              
            }
        });
    }

    const tongyiAi = async () => {
      if (newMessage.value != '') {
        let new_value = {
          "role": "user",
          "content": newMessage.value,
          "extra": fileUploadName.value ? fileUploadName.value : '',
        }
        messages.value.push(new_value);
        fileUploadName.value = '';//清空文件内容
      }
        
        const requestMessage = JSON.parse(JSON.stringify(messages.value));
        nextTick(()=>{
          scrollBottom();
        });
        newMessage.value = '';

        let tmpContent = reactive({
          "role": "assistant",
          "content": "⚫"
        });
        messages.value.push(tmpContent);
        
        const ctrl = new AbortController();
        fetchEventSource('/aiApi/data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              "messages": requestMessage,
              "webSearch": internetFlag.value
            }),
            signal: ctrl.signal,
            onmessage(msg) {
              if (msg.event == 'FatalError') {
                throw new FatalError(msg.data);
              }

              if (msg.event == 'close') {
                ctrl.abort()
              }

              if (msg.event == '') {
                try {
                  tmpContent.content = tmpContent.content.replace(/⚫$/, "");

                  if (msg.data == '[DONE]') {
                    ctrl.abort()
                    return;
                  }
                  
                  const data = JSON.parse(msg.data);
                  tmpContent.content +=  data.choices[0].delta.content+'⚫';
                  
                  nextTick(()=>{
                    scrollBottom();
                  });
                } catch (err) {
                  console.log(err)
                  throw err;
                }
              }
              
            }
        });
    }

    const chatGpt = async () => {
      if (newMessage.value != '') {
        let new_value = {
          "role": "user",
          "content": newMessage.value,
          "extra": fileUploadName.value ? fileUploadName.value : '',
        }
        messages.value.push(new_value);
        fileUploadName.value = '';//清空文件内容
      }

      const requestMessage = JSON.parse(JSON.stringify(messages.value));
      nextTick(()=>{
        scrollBottom();
      });
      newMessage.value = '';

      let tmpContent = reactive({
        "role": "assistant",
        "content": "⚫"
      });
      messages.value.push(tmpContent);

      const ctrl = new AbortController();
      fetchEventSource('/aiApi/chatgpt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "messages": requestMessage,
          "webSearch": internetFlag.value,
          "engineType": engineType
        }),
        signal: ctrl.signal,
        onmessage(msg) {
          if (msg.event == 'FatalError') {
            throw new FatalError(msg.data);
          }

          if (msg.event == 'close') {
            ctrl.abort()
          }

          if (msg.event == '') {
            try {
              tmpContent.content = tmpContent.content.replace(/⚫$/, "");
              if (msg.data == '[DONE]') {

                ctrl.abort()
                return;
              }

              const data = JSON.parse(msg.data);
              if (data.choices[0].delta.content != undefined) {
                tmpContent.content +=  data.choices[0].delta.content+'⚫';
              }

              nextTick(()=>{
                scrollBottom();
              });
            } catch (err) {
              console.log(err)
              throw err;
            }
          }

        }
      });
    }

    const aiOperate = (aiType, askContent = '') => {
      if (askContent != '') {
        newMessage.value = askContent;

        if (ai_type == 'zhipuai') {
          zhiPuAi();
        }

        if (ai_type == 'kimi') {
          kimiAi();
        }

        if (ai_type == 'tyqw') {
          tongyiAi();
        }

        if (ai_type == 'chatgpt' && askContent.length>0) {
          chatGpt();
        }
      }

      
      // chatGpt();
      
      // xinghuoAi();
    }

    if (askContent != '') {
      aiOperate(ai_type, askContent)
    }

    const sendMessage =  () => {
      let askConten = newMessage.value.trim();
      if (askConten !== '') {
        aiOperate(ai_type, askConten)
      }
    }

    const isFocused = ref(false);
    
    const handleFocus = () => {
      isFocused.value = true;
    }
    const handleBlur = () =>{
      isFocused.value = false;
    }
 
    const scrollBottom = ()=>{
        msgContainerRef.value.scrollTop = msgContainerRef.value.scrollHeight;
    }

    
    // 处理文件选择事件
    const handleUploadFileChange = (event) => {

      if (ai_type == 'tyqw') {
        tyqwUpload(event)
      }
      
    };

    const tyqwUpload = (event) => {
      const file = event.target.files[0];
      
      const allowedTypes = [
        'text/plain',  // txt
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  // docx
        'application/pdf',  // pdf
        'application/epub+zip',  // epub
        'application/x-mobipocket-ebook',  // mobi
        'text/markdown'  // md
      ];

      if (file && !allowedTypes.includes(file.type)) {
        ElMessage({
          message: '文件格式支持常见的文本文件(txt docx pdf epub mobi md)',
          type: 'error',
        })
        event.target.value = ''; // 清空文件输入
        return;
      }

      if (file) {
        fileUploadName.value = file.name;
        // 执行上传操作（例如，通过 API 上传文件）
        uploadFile(file, event);
      }
    }

    // 上传文件到服务器
    const uploadFile = async (file, event) => {
      fileLoading.value = true;
      const formData = new FormData();
      formData.append("file", file);

      try {
        const res = await axios.post("/aiApi/tongyiupload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(res);
        if (res.data.status == 200) {
          let new_value = {
            "role": "system",
            "content": `fileid://${res.data.message.id}`
          }
          messages.value.push(new_value);
          fileLoading.value = false;
        } else {
          ElMessage({message: '上传失败', type: 'error', })
          event.target.value = ''; // 清空文件输入
          fileUploadName.value = '';
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        ElMessage({message: '上传失败', type: 'error', })
          event.target.value = ''; // 清空文件输入
          fileUploadName.value = '';
      }
    };

     // 点击按钮时触发文件输入框
     const triggerFileUploadInput = () => {
      fileUploadInput.value.click();
    };


    

    return {
      ai_type,
      messages,
      newMessage,
      msgContainerRef,
      sendMessage,
      aiRobotAvatar,
      aiVersionName,

      internetFlag,

      isFocused,
      fileUploadInput,
      fileUploadName,
      fileLoading,
      handleFocus,
      handleBlur,
      triggerFileUploadInput,
      handleUploadFileChange
    };
  }
}
</script>

<style scoped>
.chat-list {
  background-color:#f9f9f9;
}
.chat-box {
  width: 100%;
  height: 80vh;
  overflow-y:auto;
}

.messages {
  width: 800px;
  margin: 0 auto;
}

.user-info {
  justify-content: flex-end;
}

.assistant-info {
  justify-content: flex-start;
}
.chat-content {
  background-color: #f4f4f4;
  display: inline-block;
  padding: 10px 16px;
}
.chat-content-assistant {
  display: flex;
}
.chat-content,.chat-content-assistant {
  
  border-radius: 6px;
  color: #0d0d0d;
  max-width: 730px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
  //white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-break: anywhere;
}

.search-input {
  border-radius:8px;
  background-color:#fff;
  padding:10px 20px;
  width:760px;
  /* margin:0px auto; */
  /* box-shadow: 5px 4px 6px #edf3ff, 5px 8px 12px #edf3ff; */
  border: 1px solid #ebeefa;
}

.search-input.focused {
  border-color: #2775ff;
}

.batch-info {
  width:32px;
  height:32px;
  background-color:#f7f7f7;
  border-radius: 50%;
}

.ai-robot-img {
  width: 30px;
  height: 30px;
  margin-top: -5px;
  margin-right:10px;
}

.search-extra-list {
  width:90px;
  margin:0 auto;
  display: flex;
  align-items:center;
  padding:10px;
  border-radius:6px;
  cursor:pointer;
}

.search-extra-list:hover {
  background-color:#EBEDF3;
}

.chat-extra {
  border: 1px solid #f4f4f4;padding: 10px 16px;border-radius:6px;margin-bottom:4px;
}

</style>
<style>
.github-markdown-body {
  padding:0px !important;
  font-size:14px !important;
}

.github-markdown-body li {
  //margin: 0 !important;
}
.github-markdown-body li>p {
  //margin: 0px !important;
  display: inline;
}
.github-markdown-body h1, .github-markdown-body h2, .github-markdown-body h3, .github-markdown-body h4, .github-markdown-body h5, .github-markdown-body h6 {
  margin: 0 !important;
}
</style>
