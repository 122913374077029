
<template>
  <el-dialog :modelValue="loginFlag" width="400" :before-close="handleClose">
    <div class="login-title">
      <div @click="setLoginType(1)" class="cursor-pointer" :class="loginType===1?'fw-600 color-main':''">扫码登录</div>
      <el-divider direction="vertical" />
      <div @click="setLoginType(2)" class="cursor-pointer" :class="loginType===2?'fw-600 color-main':''">验证码登录</div>
    </div>
    <div class="login-tips">登录通言AI，获取更多AI技术服务</div>

    <div class="mt-50 login-input">
      <el-input v-model="inputPhone" style="width: 280px" placeholder="手机号" size="large"/>

      <el-input v-model="inputPhone" style="width: 280px" class="mt-30" placeholder="输入验证码" size="large">
        <template #append><span class="cursor-pointer">{{inputCodeActionName}}</span></template>
      </el-input>

      <el-button type="primary" style="width: 280px" class="mt-40" size="large" @click="loginAct">登录/注册</el-button>

      <div class="login-desc">
        注册登录即代表同意《注册协议》《隐私政策》
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { defineProps, ref ,defineEmits } from 'vue';
import {apiLogin} from '@/api/user/login'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  loginFlag: {
    type: Boolean,
    default: false,
  },
});

// const loginVisible = ;
const loginType = ref(2);

const setLoginType = (i) => {
  loginType.value = i;
}
const handleClose = () => {
  emit('update-prop', false);
}
const emit = defineEmits(['update-prop']);

const inputPhone = ref("");
const inputCodeActionName = ref("获取验证码");

const loginAct = () => {
  let param = {
    phone: "18556786960",
    code: '6960',
  }
  apiLogin(param).then(res => {
    if (res.data.err_no === 0) {
      localStorage.setItem('api_token', 'Bearer ' + res.data.results.token);
      localStorage.setItem('is_login', 1);
    }
  })
}
</script>

<style scoped>
.login-title {
  display: flex;
  margin: 0 auto;
  width:400px;
  justify-content: center;
  align-items: center;
}

.login-tips{
  color:#202020;
  font-size: 16px;
  margin-top:30px;
}

.login-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color:#8c8c8c;
  margin-top:40px;
  margin-bottom:30px;
}
</style>
<style>
.el-dialog {
  border-radius: 12px !important;
}
</style>