<template>
  <div class="logo-info">
    <router-link to="/"><span class="fs-26 fw-bold color-weight">通言AI</span></router-link>
  </div>
  <div style="padding:0 13px;">
    <div class="module-info">
      <div class="text-left column-module">
        <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E8%AF%84%E8%AE%BA_comment.png" />
        <h3>AI对话</h3>
      </div>
      <div class="text-left column-list">
        <span>历史对话</span>
      </div>
    </div>
    <div class="module-info">
      <router-link to="/aiset">
        <div class="text-left column-module">
          <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E5%BE%AA%E7%8E%AF%E7%A7%BB%E5%8A%A8_cycle-movement.png" />
          <h3>精选AI产品集</h3>
        </div>
      </router-link>
    </div>
    <div class="module-info">
      <router-link to="/es">
        <div class="text-left column-module">
          <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E4%BC%81%E4%B8%9A%E5%8F%B7_qiyehao.png" />
          <h3>企业服务</h3>
        </div>
      </router-link>
    </div>
    <div class="module-info">
      <router-link to="/bs">
        <div class="text-left column-module">
          <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E9%AD%94%E6%96%B9_cube-three.png" />
          <h3>业务板块</h3>
        </div>
      </router-link>
    </div>
<!--    <div class="module-info no-border">-->
<!--      <div class="text-left column-module">-->
<!--        <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E4%BB%AA%E8%A1%A8%E7%9B%98_dashboard.png" />-->
<!--        <h3>数据大屏</h3>-->
<!--      </div>-->
<!--      <a href="https://greentms.crrcgce.com/big/#/" target="_blank">-->
<!--        <div class="text-left column-list color-weight"><span>智慧车运</span></div>-->
<!--      </a>-->
<!--      <a href="https://jg.senyuanyuan.com/admin/#/login?source=Y3JlZW5CaWc%3D&secret=Y3JlZW5CaWcxMjM0" target="_blank">-->
<!--        <div class="text-left column-list color-weight"><span>智慧垃圾分类</span></div>-->
<!--      </a>-->
<!--      <a href="https://www.wanjunshijie.com/demo/shujudaping/guangfuzhineng/vue3/#/" target="_blank">-->
<!--        <div class="text-left column-list color-weight"><span>智慧光伏</span></div>-->
<!--      </a>-->
<!--      <a href="https://cold-chain.crrcgce.com/#/pages/fullscreen/cold-box-screen?tokens=90cc4e3dc487ac2cf97e50bc998d67246ee1f6af15769d0e95f1087009528b59" target="_blank">-->
<!--        <div class="text-left column-list color-weight"><span>智慧冷链集配</span></div>-->
<!--      </a>-->
<!--      <a href="https://cold-chain.crrcgce.com/#/pages/fullscreen/cold-machine-screen?tokens=ffbeb0b6cb9018c8e6b85872ca815fcf645b8f01a8be6a4f667fdad767e06cc1" target="_blank">-->
<!--        <div class="text-left column-list color-weight"><span>智慧冷链充冷</span></div>-->
<!--      </a>-->
<!--    </div>-->
  </div>
  <div class="fixed-bottom" style="">
    <div class="login-info cursor-pointer" @click="showLogin" v-show="loginStatus!=1">
      <el-icon><User /></el-icon>
      <span class="ml-10">登录</span>
    </div>
    <div class="login-info cursor-pointer" v-show="loginStatus==1">
      <span>{{userInfo.userName}}</span>
    </div>
  </div>

  <Login :loginFlag="loginFlag" @update-prop="updateLoginFlag" />
</template>

<script>
import Login from './Login.vue'
import {ref, onMounted} from "vue";
import {apiGetUserInfo} from '@/api/user/login'

export default {
  components: { Login },
  setup() {
    const loginStatus = ref(localStorage.getItem('is_login'));
    const userInfo = ref({
      userName: '',
    });
    const getUserInfo = () => {
      apiGetUserInfo().then(res => {
        if (res.data.err_no === 0) {
          let data = res.data.results;
          localStorage.setItem('user_info', JSON.stringify(data));
          userInfo.value.userName = data.phone;
        }
      })
    }

    onMounted(() => {
      if (parseInt(loginStatus.value) === 1) {
        getUserInfo();
      }
    });

    const loginFlag = ref(false);
    const showLogin = () => {
      loginFlag.value = true;
    }

    function updateLoginFlag(newValue) {
      loginFlag.value = newValue;
    }

    return {
      loginStatus,
      loginFlag,
      userInfo,
      showLogin,
      updateLoginFlag
    }
  }
}
</script>

<style>
.login-info {
  display: flex;
  align-items: center;
  font-size:14px;
}


.logo-info {
  height:36px;
  margin:0 20px;
  padding:8px;
  border-bottom: 1px solid #2b2d31;
}
.module-info {
  border-bottom: 1px solid #2b2d31;
}
.column-module {
  border-radius: 6px;
  padding:0px 8px;
  display:flex;
  align-items: center;
  color:#fff;
}
.column-module h3 {
  margin-left:10px;
  font-weight: 600;
  font-size: 14px;
}
.column-module:hover {
  cursor: pointer;
  background-color:#2b2d31;
}
.column-list {
  border-radius: 6px;
  padding:10px 8px;
  display:flex;
  align-items: center;
}
.column-list span {
  margin-left:24px;
  font-size: 14px;
}
.column-list:hover {
  background-color:#2b2d31;
  cursor: pointer;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width:160px;
  margin:10px;
  text-align: center;
  padding-top:20px;
  padding-bottom: 20px;
  border-top:1px solid #2b2d31;
}


</style>