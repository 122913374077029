// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import Chat from '../views/Chat.vue'
import ImageChat from '../views/Chat/ImageChat.vue'
import AiIndex from '../views/AiIndex.vue'
import ChatModule from '../views/ChatModule.vue'
import EnterpriseServices from '../views/EnterpriseServices.vue'
import WssScreen from '../views/LargeScreen/WssScreen.vue'


import AiSet from '../views/AiSet.vue'
import BusinessSegments from "@/views/BusinessSegments.vue";



const routes = [
  {
    path: '/',
    name: 'AiIndex',
    component: AiIndex,
    meta: {
      title: '中达通智慧平台-通言AI'
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    meta: {
      title: '中达通智慧平台-通言AI'
    }
  },
  {
    path: '/imgChat',
    name: 'ImgChat',
    component: ImageChat,
    meta: {
      title: '中达通智慧平台-通言AI'
    }
  },

  {
    path: '/aiset',
    name: 'AiSet',
    component: AiSet,
    meta: {
      title: '中达通智慧平台-通言AI'
    }
  },
  {
    path: '/chatm',
    name: 'ChatModule',
    component: ChatModule,
    meta: {
      title: '中达通智慧平台-通言AI'
    }
  },
  {
    path: '/es',
    name: 'EnterpriseServices',
    component: EnterpriseServices,
    meta: {
      title: '中达通智慧平台-通言AI'
    }
  },
  {
    path: '/bs',
    name: 'BusinessSegments',
    component: BusinessSegments,
    meta: {
      title: '业务板块-通言AI'
    }
  },
  {
    path: '/wssscreen',
    name: 'WssScreen',
    component: WssScreen,
    meta: {
      title: '中达通智慧平台-通言AI'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
