<template>
  <el-container class="common-layout">
    <el-aside width="180px" style="background-color:#000;" class="color-fa" v-if="currentRoutePath!='/wssscreen'">
      <Header />
    </el-aside>
    <div style="flex: 1;overflow-y: auto; ">
      <router-view></router-view>
    </div>
  </el-container>
</template>

<script>
import '@/assets/css/common.css';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import Header from '@/components/common/Header.vue'
export default {
  name: 'App',
  components: {
    Header
    // Body,
    // Foot
  },
  setup() {
    
    const route = useRoute();
    const currentRoutePath = ref(route.path);

    watch(route, (newRoute) => {
      currentRoutePath.value = newRoute.path;
    });

    return {
      currentRoutePath,
    };
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

.common-layout {
  height: 100vh;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
